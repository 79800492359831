.mobile-view-header{
    width: 100%;
    background-color : rgba(4, 4, 4);
    color: black;
    display: flex;
    align-items: right;
    cursor: pointer;
    justify-content: right;
  }
  

  .fix-name{

     position: absolute;
     padding: 10px;
     top: 2px;
  color:gray;

  z-index: 1;
  }
  /* .mobile-view-header{
  background-color: transparent;
  width: 100%;
  height: 0px;
  display: flex ;
  align-items: center;
  top: 30px;
  text-align: center;
   justify-content: right;
   position:sticky;
   z-index: 1;
   opacity: 0.5,
  } */
  
  .mobile-view-header p svg{
    cursor: pointer;
    color: white;
    margin-top: 10px;
    justify-content: right;
  }
  
  
  .responsive-mobile-view{
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .mobile-nav{

    height: auto;
    width: 280px;
    background-color: #ece7e7;
    margin-top: 1px;
    top: 0;
    z-index: 1;
    
    
  }
  .mobile-view-right{

     display: flex;
     justify-content: right;
     float: right;
   

  }
  
  .mobile-nav ul li{
    color: black;
    cursor: pointer;
    margin-top: 10px;
    justify-content: right;
    padding-left: 20px;
  }
  
  
  @media (min-width: 900px) {
    
    .responsive-mobile-view{
        display: none;
    }
  
  }
  
  
  @media (max-width: 890px) {
    
  .mobile-nav{
    display: flex;
  }
  
  }
  
  