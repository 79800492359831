.feedback-slider {
    height: 80vh;
    margin-top: 50px;
  
  }
  
  #dark .feedback-slider {
    color: white;
  }
  
  
  
  .feedbck-slide{
      margin-top: 30px;
  }
  
  .content-slider {
    padding: 10px;
    color: black;
    /* background-color: #ff854c; */
    margin: 10px;
    margin-top: 50px;
    text-align: left;
    margin-bottom: 30px;
     border: solid whitesmoke;
     border-color: #b75616;
    cursor: pointer;
    
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
  }
  
  
  #dark .content-slider{
    background-color: #38004c;
    color: white;
  
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
  
  }
  
  
  
  .content-slider:hover {
    background-color:  rgb(248, 232, 232);
  
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;
    cursor: pointer;
  }
  
  .content-slider img.center-image {
    height: 80px;
    width: 80px;
    border-radius: 90px;
    display: block;
    /* margin: 0 auto; */
    object-fit: cover;
    margin-top: 10px;
    justify-content: left;
    align-items: left;
  }
  
  .slick-prev:before{
      font-size: 30px;
      color: #b75616!important;
  }
  .slick-next:before{
      font-size: 30px;
      color: #b75616!important;
  }
  
  .slick-disable{
      /* opacity: 0; */
      pointer-events: none;
  }


.testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: color-mix(in srgb, var(--accent-color), transparent 60%);
  font-size: 26px;
  line-height: 0;
}


.testimonial-item {
    box-sizing: content-box;
    min-height: 200px;
    position: relative;
    margin: 30px;
  }
  
  .testimonial-item .testimonial-img {
    width: 90px;
    height: 90px;
    object-fit: co;
    border-radius: 50px;
    border: 6px solid var(--background-color);
    margin-right: 10px;
  }
  
  .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
  }
  
  .testimonial-item h4 {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 14px;
    margin: 0;
  }
  
  .testimonial-item .stars {
    margin: 10px 0;
  }

.testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonial-slide
{
   
   width: 96%;

}