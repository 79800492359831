.faq {

    padding-top: 10px;
    height: auto;
    margin-bottom: 20px;
    padding-bottom: 20px;
    

}

.mian-content{

    background-color: whitesmoke;

}
.post-card{

    height:auto;

    margin-top: 10px;
    
    box-shadow: 5px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -webkit-box-shadow: 5px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -moz-box-shadow: 5px 9px 9px -2px rgba(39, 25, 25, 0.86);
    cursor: pointer;
    border: solid thin gainsboro;
    background-color: white;
}


.post-card:hover{

    border-left: 5px solid rgb(173, 156, 90);
    cursor: pointer;
    transition-duration: 1s;
    padding-left: 10px;

}
.collapse-option{

    display: flex;
    justify-content: space-between;
    height: 80px;
    padding-top: 15px;
   
}

.post-card-open{

    border-left: 5px solid blueviolet;
    background-color: lightgrey;
   
    cursor: pointer;
    
}

.description {

    height: auto;
    padding: 5px ;
    padding-left: 35px;
}
.main-content {

    padding-left: 15px ;
}

.content-arrow{
     
    width: 30px;
    margin: 5px;
}

