

@media (max-width:1000px) {
    .services-section
    {
       
       height: auto; 
    }

}

@media (min-width:1000px) {
    .services-section
    {
         height: 700px; 
        /* height: auto; */
    }

}

.services-image img{
    border-radius: 190px;
    margin-top: 160px;
    object-fit: cover;
   

}
.services-image-content
{
 text-align: center;

    margin: 5px auto;
}
.services-details-main
{ 

   padding-top: 20px;
   text-align: left;
   /* padding: 20px; */

}
.services-details
{ 

   text-align: left;
   padding-bottom: 20px;

}

.services-title h5{
    text-align: center;
}
.line{

    display: block;
    width: 100px;
    height: 3px;
    margin: 5px auto;
    background-color: red;
}
.card-design {
    box-shadow: 5px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -webkit-box-shadow: 5px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -moz-box-shadow: 5px 9px 9px -2px rgba(230, 224, 224, 0.86);
    cursor: pointer;
}

#dark .card-design{

  background-color: #38004c;
  margin-top: 10px;

  box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
}



.card-design:after{
    display: block;
    content: "";
    border-bottom: solid 3px orangered;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}


.card-design:hover{
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;

}


.card-design:hover:after{
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;
    transform: scaleX(1);
    transform-origin: 50% 50% 10% 60%;
}


.services-list-item {
  display: flex;
  padding: 10px;
}
.services-details-icon{
    padding-right: 10px;
    color: purple;
}

.service-item .icon {
    width: 48px;
    height: 48px;
    position: relative;
    margin-right: 50px;
    line-height: 0;

  }

  .service-item{
    padding-left: 4px;
    padding-top: 12px;
  }


  .icon
  {
    color: rgba(238,111,87,0.5);
    font-weight: bold;
  }

  /* .icon-line {
    padding: 30px 0 8px;
    position: relative;
  }
  
  .icon::before {
    content: "";
    position: absolute;
    left: 7%;
    bottom: 16px;
    width: 150px;
    height: 14px;
    transform: skew(-12deg) translateX(-50%);
    background: rgba(238,111,87,0.5);
    z-index: -1;
  } */