
.filterItem{
 
    scroll-behavior: smooth;
    
  }
  .galleryItem img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    
    .galleryContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
   
    }
    
    @media (max-width: 1000px) {
      .galleryContainer {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
      }
    }
    
  
    .galleryWrapper {
      padding: 1% 10%;
      
    }
    
    .filterItem ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0;
      gap: 10px;
      margin-bottom: 30px;
    }
    
    .filterItem ul button {
      background: #e97b3f;
      color: #fff;
      padding: 4px 20px;
      text-transform: uppercase;
      border: navajowhite;
      font-size: 12px;
      border-radius: 50px;
    }
    
    .filterItem ul button:hover {
      background: #222;
      cursor: pointer;
    }
    
    /* .galleryItem img {
      border-radius: 15px;
    } */