.card{

    
    border: solid thin gainsboro;
}

.card:hover{

    background-color: gainsboro;
}


.info-item  {
    background: color-mix(in srgb, var(--default-color), transparent 96%);
    padding: 30px;
    height: 220px;

  }
  
 .info-item i {
    font-size: 38px;
    line-height: 0;
    color: var(--accent-color);
  }
  
 .info-item h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 10px 0;
  }
  
.info-item p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .icon-contact
  {
     color: #b75616;


  }

  
  .button {
    background-color:#df4512;
    width: auto;
    padding: 10px;
    color: white;
    text-align: center;
    justify-content: center;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .button:hover {
    background-color:  #38004c; 
    width: auto;
    padding: 10px;
    color: white;
    text-align: center;
    justify-content: center;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    transition-duration: 1s;
  }
  