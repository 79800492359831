.navbar-main
{
     background-color: transparent;
  width: 100%;
  height: 0px;
  display: flex ;
  align-items: center;
  top: 30px;
  text-align: center;
   justify-content: right;
   position:sticky;
   z-index: 1;
   opacity: 0.5,

   
}


.navbar-scroll-color
{
 top: 0px;
 height: 55px;
 margin-top: 0px;
 background-color : rgba(4, 4, 4, 0.6)


}

.navbar-main ul
{
    display: flex;
    margin-right: 10%;

}

.navbar-main ul li
{
   list-style-type: none;
   margin-right: 50px;
   color: white;
   cursor: pointer;
   padding-top: 4px;
   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
   
}

.navbar-main ul li:hover
{
    color: yellow;
}


.active-navbar {
    border-bottom: solid;
}


@media (min-width: 900px) {
    .mobile-veiw-header  {
       display: contents;
    }
  }
  

  
@media (max-width: 899px) {
    .navbar-main  {
       display:none;
    }
  }
  
