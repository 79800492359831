.status
{
    height:300px;
    color: #fff;
    background-color: rgb(0, 0, 0,0.5);

    position: relative;
    margin-top: 50px;
    margin-bottom: 30px;
}
@media (max-width: 1000px) {
    .status {
        height: 550px;
    }
  }
  


.status:before{
    content: "";
    background: url("../../image/stats-bg.jpg") no-repeat center center/cover ;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    z-index: -1;
}


.status-content{
    margin-top: 20px;
    vertical-align: middle;
    height: 0 auto;
    margin: 0 auto;
    position: relative;
    top: 50%;

    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
}

.counter-label{


    font-size: 50px;
    font-weight: bolder;
}