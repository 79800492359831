
.image-organization img
{

    height: 100px;
    width: 100px;
    border-radius: 90px;
}

.image-organization:hover img
{
border: solid 3px yellow;
border-radius: 60px;
height: 120px;
width: 120px;
}

.org-name{

    font-size: 18px;
    font-weight: bold;
    padding: 10px;
}

.org-position
{
    font-size: 18px;
    padding: 10px;
    padding: 5px;
}

.org-department
{
    font-size: 16px;
    
    
}


.card-organization {

    border: none;

}

.card-organization:hover {
 
    background: white;
}

.fixed-w-compnent {

    position: relative;
    background-color: white;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto ;
     
  }

  
  @media (max-width: 1000px) {
    .fixed-w-compnent {

        position: relative;
        background-color: white;
        width: 100%;
 
        top: 0;
        left: 0;
        overflow: auto ;
         
      }
    }
    