

.footer{

    height: auto;
    margin: 0px;
    background-color:  whitesmoke;;
    margin-top: 60px;
    color: rgb(32, 28, 28);
    
}
.footer-about-section{
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgb(32, 28, 28);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.footer-social-icons{
    display: flex;
    justify-content: left;
    width: auto;
}
.footer-social-icons p{
    height: 40px;
    width: 40px;
    background-color: rgb(232, 141, 14);
    color: rgb(32, 28, 28);
    border-radius: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px;
    cursor: pointer;
}


.footer-social-icons p:hover {
    background-color: rgb(136, 9, 215);
    cursor: pointer;
  }

.footer-item li{
    color: rgb(32, 28, 28);
    list-style-type: none;
    padding-top: 4px;
}

.footer-item p {
    padding-top: 4px;
}

li{
    --border-color:  rgb(32, 28, 28);
    --border-color2: rgb(10, 207, 75);
    --border-width: 2px;
    --bottom-distance: 0px;
    display: block;
    background-image: linear-gradient(var(--border-color),var(--border-color2));
    background-size: 0% var(--border-width);
    background-repeat: no-repeat;
    transition: background-size 0.5s;
}

.footer-item li{
    background-position: 0 calc(100% - var(--bottom-distance));
    color:  rgb(32, 28, 28);
    width: fit-content;
}
.footer-item li:hover{
    background-size: 100% var(--border-width);
    cursor: pointer;

}

.footer-service{
    padding-bottom: 40px;
}


.footer-section
{
   padding-top: 20px;
}