.video-section
{
    padding-top : 50px;
    margin: auto;
}

.video-details
{

    width: auto;
    padding: 10px;
    box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -webkit-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -moz-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    cursor: pointer;
    border-radius: 10px;

}

.video-details:hover
{

    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;
}
.video-details h6
{

  padding-top: 10px;
}