.h22 {
    font-family: "Libre Baskerville", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 40px;
  }
  
  
  .h33 {
    font-family: "Montserrat", serif;
    font-weight: 300;
    font-style: normal;
    font-size: 25px;
  }
  
  .h44 {
    font-family: "Playfair Display", serif;
    font-weight: 100;
    font-style: normal;
  }


.image-feature{

    border-radius: 60px;
    border: solid;
    border-color: gainsboro;
}

.section_title
{

     height: auto;

}

.team-member-item img{

   
    cursor: pointer;
    object-fit: cover;

    transition: transform 2s, filter 1.5 ease-in-out;
    transform-origin: center;
    filter:brightness(90%);
}


.team-member-item:hover img{


    filter:brightness(100%);
    transform: scale(1.30) ;
    transition-duration: 1s;
}

.team-info{
 
    padding: 20px;
}

.card {
    border: none;
}

.team-member-item{
    height: auto;
     margin-top: 20px;
   overflow: hidden;
     box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
     -webkit-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
     -moz-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    cursor: pointer;
   
}


.team-member-item:hover{
   
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;
    cursor: pointer;
}

.line-for-teammembers
{
    width: 50%;
    display: block;
    height: 1px;
    margin:  0px auto;
    background-color: black;
}

.social-icons
{
    display: flex;
   justify-content: center;
}

.social-icons p
{
     height: 40px;
     width: 40px;
     background-color: rgb(52,51, 40);
     color: white;
     border-radius: 90px;
     display: flex;
     justify-content: center;
     align-items: center;
    margin: 20px;     

}
.social-icons p:hover
{
     
     background-color: brown;
    transition-duration: 1s;
    cursor: pointer;

     
}
.content-description{

    padding-top: 20px;
  }

  
  .section-name-vertical-center {

    margin-top: 20px;
    vertical-align: middle;
    height: 0 auto;

    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

}

.main-content-image-left{

   padding: 30px;  

}


.main-content-image-right{

    padding: 30px;  
    
 }