
.clients-background{

  background-color: rgb(247, 245, 243);
  margin-bottom: 20px;

}
.clients {
    padding: 20px 0;
    margin: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
   
  }
  
  .client-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
   .client-logo img {
    padding: 20px 40px;
    max-width: 90%;
    transition: 0.3s;
    opacity: 0.5;
    filter: grayscale(100);
  }
  
  .client-logo img:hover {
    filter: none;
    opacity: 1;
  }
  
  @media (max-width: 640px) {
    .clients .client-logo img {
      padding: 20px;
    }
  }
  
