.home{

    height: 100vh;
    color: #fff;
    background-color: rgb(0, 0, 0,0.5);
    position: relative;


}
.home::before
{
    content: "";
    background: url("../../image/home.jpg") no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top:0;
    z-index: -1;
}
.home-content{
 
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 10px;
}

.home {

  font-family: "Open Sans",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Montserrat",  sans-serif;;

}

.view-details-home-button{
    background-color: #b75616;
    text-align: center;
    width: 20%;
    height: 40px;
    border-radius: 6px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    cursor: pointer;
    padding-top: 5px;
}

.view-details-home-button:hover{

    background-color: white;
    color: black;
    transition-duration: 2s;

}
.about-list-icon{
    padding-right: 10px;
    
}

  
  @media (max-width: 1000px) {
    .home {
        height: 550px;
    }

    .view-details-home-button{
        background-color: #b75616;
        text-align: center;
        width: 50%;
        height: 40px;
        border-radius: 6px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        cursor: pointer;
        padding-top: 5px;
    }
    

  }
  

  