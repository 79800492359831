.section_title
{
   
    padding-top: 50px;
     height: auto;

}

.team-member-item img{

    height: 240px;
    cursor: pointer;
    object-fit: cover;

    transition: transform 2s, filter 1.5 ease-in-out;
    transform-origin: center;
    filter:brightness(50%);
}


.team-member-item:hover img{


    filter:brightness(100%);
    transform: scale(1.15) ;
    transition-duration: 1s;
}

.team-info{
 
    padding: 20px;
}

.card {
    border: none;
}

.team-member-item{
    height: auto;
     margin-top: 20px;
   overflow: hidden;
     box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
     -webkit-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
     -moz-box-shadow: 15px 9px 9px -2px rgba(230, 224, 224, 0.86);
    cursor: pointer;
   
}


.team-member-item:hover{
   
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;
    cursor: pointer;
}

.line-for-teammembers
{
    width: 50%;
    display: block;
    height: 1px;
    margin:  0px auto;
    background-color: black;
}

.social-icons
{
    display: flex;
   justify-content: center;
}

.social-icons p
{
     height: 40px;
     width: 40px;
     background-color: rgb(52,51, 40);
     color: white;
     border-radius: 90px;
     display: flex;
     justify-content: center;
     align-items: center;
    margin: 20px;     

}
.social-icons p:hover
{
     
     background-color: brown;
    transition-duration: 1s;
    cursor: pointer;

     
}