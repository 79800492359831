
::-webkit-scrollbar{
  width: 8px;
  height: auto;
}
::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px rgb(103, 100, 109);
}

::-webkit-scrollbar-thumb{
  background-color: #FF854C;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover{
  background-color: #3b2c44;
}

@import "~react-image-gallery/styles/css/image-gallery.css";



*{
  margin: 0px;
  box-sizing: border-box;
  &:before,
  &:after{
    box-sizing: border-box;
  }
}

body {
  margin: 0px;
  width: 100%;
  font-family: sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing:antialiased;

}

button, input, textarea, select {
  font: inherit;

}

p, h1,h2,h3,h4,h5,h6 {

  overflow-wrap: break-word;
  
}

h1 {
  font-family: "Montserrat", system-ui;
  font-weight: 400;
  font-style: normal;
}

h2 {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-style: normal;
}


h3 {
  font-family: "Lato", sans-serif;
  font-weight: 200;
  font-style: normal;
}

h4 {
  font-family: "Playfair Display", serif;
  font-weight: 100;
  font-style: normal;
}

p {
  
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  
  
}


img, video, canvas, svg{
  max-width: 100%;
}

a {

  text-decoration: none;
  color: inherit;
}
ul{

   list-style: none;
   margin: 0;
   padding: 0;

}

button{

  border: none;
  outline: none;
}


.section_title h5 {
  text-align: center;
}
.section_line {
  display: block;
  width: 100%;
  height: 0.1px;
  margin-bottom: 10px;
  background-color: #6e600e;
}


.section_title h1 {
  display: inline;
  border-bottom: 3px solid rgb(243, 139, 98);



}
    

.section_title h2 {
  display: inline;
  border-bottom: 3px solid rgb(243, 139, 98);
  margin: auto;
  width: 50%;
  padding: 10px;
  font-weight: bolder;

}
    

.section_title_1 h2 {
  display: inline;
  border-bottom: 3px solid rgb(243, 139, 98);
  margin: auto;
  width: 50%;
  padding: 10px;
  font-weight: bolder;

  font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;

}
    

.section_title_about_1{

  margin: auto;
  width: 100%;
  padding: 10px;
text-align: center;

font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;

}

/* Revised Header */

.section_title_main{

  text-align: center;
  margin-top: 10px;
    margin-bottom: 30px;

}

.section_title_main h2{

  width: 100%;
  padding: 10px; 
  text-align: center;
  font-family: "Montserrat", system-ui;
  font-weight: 500;
  font-style: normal;
  font-size: 35px;

}
.section_title_main_side{

  text-align: center;
  padding: 20px;


}

.section_title_main_side h2{

  margin-bottom: 30px;
  width: 100%;
  padding: 10px; 
  text-align: center;
  font-family: "Montserrat", system-ui;
  font-weight: 500;
  font-style: normal;
  font-size: 35px;


}

.section_title_main_side-Details {

  padding: 30px;
  text-align: justify;

}


