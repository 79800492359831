.image-feature-main{

padding-top: 5%;

}

.about-section {
    height: auto;
    padding: 20px;

  }
  #dark .about-section {
    color: white;
  }
  
  @media (max-width: 1000px) {
    .about-section {
      margin-top: 25px;
    }
  }

  .icon-content{
    padding: 10px;
  }
  .about-icon {

    height: 60px;
    width: 60px;
    border-radius: 90px;
    object-fit: cover;
  
    border: 3px solid rgb(243, 139, 98);
    background-color: rgb(238, 178, 132);
   

  }
  .about-icon-content
  {
    
    vertical-align: middle;
    margin-top: 20%;
    text-align: center;
  }

  .section-name {

       margin-top: 20px;
       vertical-align: middle;
       height: 0 auto;

       margin: 0;
       position: relative;
       top: 50%;
       -ms-transform: translateY(-50%);
       transform: translateY(-50%);

  }

  .content-card {
    margin-top: 50px;
    color: black;
     margin-left: 10px;

    box-shadow: 5px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -webkit-box-shadow: 5px 9px 9px -2px rgba(230, 224, 224, 0.86);
    -moz-box-shadow: 5px 9px 9px -2px rgba(230, 224, 224, 0.86);
    cursor: pointer;
    min-height:  250px;
  }
  
  
  #dark .content-card{
    background-color: #38004c;
    color: white;
  
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
  
  }
  
  #dark .content-card:hover{
    background-color: #38004c;
    color: white;
  
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    border-bottom: 2px solid yellowgreen;
  
  }
  
  
  
  
  .content-card:hover {
    background-color: brown;
    color: rgb(27, 22, 22);
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;
  }
  
  .marked-content-card {
    background-color: brown;
    padding: 10px;
    color: black;
    margin-top: 50px;
  }
  
  
  #dark .marked-content-card{
    background-color: #4a0a46;
    color: black;
  
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
  
  }
  
  
  
  .marked-content-card:hover {
    box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -6px 11px 5px -4px rgba(0, 0, 0, 0.42);
    transition-duration: 1s;
  }
  
  .marked-content-card img {
    height: 80px;
    width: 80px;
    border-radius: 90px;
    object-fit: cover;
    margin-top: -30px;
    border: 3px solid rgb(241, 205, 21);
  }
  
  .content-card img {
    height: 80px;
    width: 80px;
    border-radius: 90px;
    object-fit: cover;
    margin-top: -30px;
    border: 3px solid rgb(241, 205, 21);
  }
  .name {
    font-weight: 20px;
    color: rgb(42, 41, 41);
    font-size: 20px;
    padding-top: 20px;
    font-weight: bold;
  }
  
  .load-more-button {
    background-color: #38004c;
    width: auto;
    padding: 10px;
    color: white;
    text-align: center;
    justify-content: center;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .load-more-button:hover {
    background-color: #df4512;
    width: auto;
    padding: 10px;
    color: white;
    text-align: center;
    justify-content: center;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    transition-duration: 1s;
  }
  
.section_title_about{

  margin: auto;
  width: 50%;
  padding: 10px;
text-align: center;

}
.content-description{

    padding: 15px;
  }

  .googleFont{

font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;

  }

  